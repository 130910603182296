<template>
  <div>
    <v-row class="mb-3">
      <h2>Tipo de Integração: <b>API</b></h2>
    </v-row>
    <v-tabs
      color="primary"
      background-color="var(--v-background-base)"
      v-model="tab"
      class="mb-4"
    >
      <v-tab style="font-size: 22px">Parâmetros</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      grow
      style="background-color: var(--v-background-base)"
    >
      <v-tab-item>
        <v-form ref="form" lazy-validation>
          <v-row class="mt-0 align-md-start">
            <v-col cols="4">
              <v-text-field
                v-model="cronExpressionLabel"
                label="Chave"
                outlined
                color="textPrimary"
                readonly
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="cronExpression"
                label="Valor"
                :rules="[rule.required]"
                outlined
                color="textPrimary"
                validate-on-blur
              />
            </v-col>
          </v-row>
          <v-row class="mt-0" v-for="(item, index) in items" :key="index">
            <v-col cols="4">
              <v-text-field
                v-model="item.key"
                label="Chave"
                :rules="[rule.required]"
                outlined
                color="textPrimary"
                validate-on-blur
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.value"
                label="Valor"
                :rules="[rule.required]"
                outlined
                color="textPrimary"
                validate-on-blur
              />
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="red" @click="removeItem(index)" class="mt-3" v-on="on">
                    fas fa-trash-alt
                  </v-icon>
                </template>
                <span>Remover Item</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="d-flex justify mt-0">
            <v-col cols="2" sm="4" md="4" lg="2">
              <v-btn block class="white--text" height="39px" color="textPrimary" @click="addItem()">
                Adicionar Novo Parâmetro
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
    <v-footer>
      <v-row class="d-flex justify-end mt-12">
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickCancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="this.isLast"
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          @click="isEdit ? onClickEdit() : onClickSave()"
        >
          Salvar
        </v-btn>
        <v-btn
          v-else
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          @click="onClickNext()"
        >
          Próximo
        </v-btn>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'API',
  props: {
    isLast: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      isEdit: false,
      configs: [],
      editConfigs: [],
      cronExpressionLabel: 'cronExpression',
      cronExpression: '',
      items: [],
    };
  },

  mounted() {
    this.verifyCollectorEdit();
  },

  methods: {
    ...mapMutations({
      setCollector: 'collectors/setCollector',
      setEditCollector: 'collectors/setEditCollector',
      setCollectorParams: 'collectors/setCollectorParams',
      setCollectorsConfigs: 'collectors/setCollectorsConfigs',
    }),
    addItem() {
      this.items.push({ key: '', value: '' });
    },

    removeItem(index) {
      this.items.splice(index, 1);
    },

    onClickCancel() {
      this.$router.push('/collectors');
    },

    onClickNext() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          const collectorsConfigs = [];
          delete this.configs.emailParameter;
          collectorsConfigs.push(this.getApiConfig(this.configs.name, this.configs.type));
          // sessionStorage.setItem('collectorsConfigs', JSON.stringify(collectorsConfigs));
          this.setCollectorsConfigs(collectorsConfigs);
        } else {
          // this.configs.push(this.getApiConfig(JSON.parse(sessionStorage.getItem('collector')).collectorName, 'API'));
          this.configs.push(this.getApiConfig(JSON.parse(JSON.stringify(this.collectorData)).collectorName, 'API'));
          // sessionStorage.setItem('collectorsConfigs', JSON.stringify(this.configs));
          this.setCollectorsConfigs(this.configs);
        }
        this.$emit('onClickNext');
      } else {
        this.tab = 0;
      }
    },

    onClickSave() {
      if (this.$refs.form.validate()) {
        this.configs.push(this.getApiConfig(JSON.parse(JSON.stringify(this.collectorData)).collectorName, 'API'));
        // this.configs.push(this.getApiConfig(JSON.parse(sessionStorage.getItem('collector')).collectorName, 'API'));
        // sessionStorage.setItem('collectorsConfigs', JSON.stringify(this.configs));
        this.setCollectorsConfigs(this.configs);
        this.$emit('onClickSave');
      } else {
        this.tab = 0;
      }
    },

    onClickEdit() {
      if (this.$refs.form.validate()) {
        // const collectorsConfigs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
        const collectorsConfigs = JSON.parse(JSON.stringify(this.collectorsConfigsData));
        collectorsConfigs.push(this.getApiConfig(collectorsConfigs[0].name, 'API'));
        delete this.configs.emailParameter;
        // sessionStorage.setItem('collectorsConfigs', JSON.stringify(collectorsConfigs));
        this.setCollectorsConfigs(collectorsConfigs);
        this.$emit('onClickEdit');
      } else {
        this.tab = 0;
      }
    },

    verifyCollectorEdit() {
      if (this.$route.query.isEdit) {
        this.isEdit = true;
        // const session = JSON.parse(sessionStorage.getItem('editCollector'));
        const session = JSON.parse(JSON.stringify(this.editCollectorData));
        const found = session.configs.find((config) => config.type === 'API');
        if (found) {
          this.configs = found;
          if (this.configs) {
            this.cronExpression = this.configs.cronExpression;
            if (this.configs.parameters !== null) {
              Object.entries(this.configs.parameters).forEach(([key, value]) => {
                if (key.trim() !== '' && value.trim() !== '') {
                  this.items.push({ key, value });
                }
              });
            }
          }
        } else {
          // this.configs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
          // this.editConfigs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
          this.configs = JSON.parse(JSON.stringify(this.collectorsConfigsData));
          this.editConfigs = JSON.parse(JSON.stringify(this.collectorsConfigsData));
        }
      } else {
        // this.configs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
        this.configs = JSON.parse(JSON.stringify(this.collectorsConfigsData));
      }
    },

    getApiConfig($name, $type) {
      return {
        name: $name,
        type: $type,
        cronExpression: this.cronExpression,
        parameters: this.items.reduce((acc, item) => {
          if (item.key.trim() !== '' && item.value.trim() !== '') {
            acc[item.key] = item.value;
          }
          return acc;
        }, {}),
      };
    },
  },

  computed: {
    ...mapGetters({
      collectorData: 'collectors/collector',
      editCollectorData: 'collectors/editCollector',
      collectorParamsData: 'collectors/collectorParams',
      collectorsConfigsData: 'collectors/collectorsConfigs',
    }),
  },

  created() {
    this.rule = new Rules();
  },
};
</script>
