/* eslint-disable */
import HttpService from '../HttpService';
export default class CollectorService {
  constructor() {
    this._httpService = new HttpService('/sdi/collector');
  }

  async FindAll(filters){
      return await this._httpService.get(`${filters}`);
  }

  async FindAllByFilters(params) {
    return await this._httpService.get('', params);
  }

  async Insert(data) {
    return await this._httpService.post('', data);
  }

  async Update(data) {
    return await this._httpService.put('', data);
  }

  async CopyCollector(data) {
    return await this._httpService.post(`/${data.id}/copy`, data);
  }

  async DeleteById(id) {
    return await this._httpService.delete(`/${id}`);
  }
}
